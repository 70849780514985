<template lang="">
  <IAmOverlay :loading="isLoading">
    <BCard>
      <b-table
        :fields="tableColumns"
        striped
        bordered
        hover
        responsive
        :empty-text="$t('noMatchingResult')"
        :items="feeConfigsData"
      >
        <!-- ANCHOR Header -->
        <template
          v-for="column in tableColumns"
          #[`head(${column.key})`]="data"
        >
          <div
            :key="column.label"
            class="text-dark text-nowrap"
            :class="{
              'text-right': ['amount'].includes(data.label),
              'text-center': ['action'].includes(data.label)
            }"
          >
            {{ data.label ? $t(`fee.columns.${data.label}`) : '' }}
          </div>
        </template>
        <template #cell(airline)="data">
          <div class="d-flex-center gap-1 font-weight-bolder">
            <div
              style="width: 50px"
              class="d-flex-center"
            >
              <IAmLogoAirline
                :airline="data.item.airline.toUpperCase() "
                :height="30"
              />
            </div>
            <span class="flex-1">
              {{ $te(`flight.airlines.${data.item.airline.toUpperCase()}`)
                ? $t(`flight.airlines.${data.item.airline.toUpperCase()}`)
                : '' }}
              ({{ $te(`flight.sourceName.${data.item.airline.toUpperCase()}`)
                ? $t(`flight.sourceName.${data.item.airline.toUpperCase()}`)
                : data.item.airline.toUpperCase() }})
            </span>
          </div>
        </template>

        <template #cell(feeType)="data">
          <div v-if="!checkAgencyFeeByTicket(data.item.airline) || checkAgencyFeeByTicket(data.item.airline)?.value?.contentSource">
            <b-form-group
              v-if="feeDataToEdit.id === data.item.id"
              label-for="feeTypeEdit"
              class="custom-form-group"
              style="min-width: 300px;"
            >
              <v-select
                v-model="feeDataToEdit.feeType"
                :append-to-body="true"
                :calculate-position="withPopper"
                :options="ticketType === 'INLAND' ? feeTypeOptions.filter(item => item.value === 'FLIGHT_ITINERARY') : feeTypeOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                :placeholder="$t('fee.placeholder.feeType')"
              >
                <template #option="{ value }">
                  <span class="d-one-line">
                    {{ $t(`fee.${value}`) }}
                  </span>
                </template>

                <template #selected-option="{ value }">
                  <span class="d-one-line">
                    {{ $t(`fee.${value}`) }}
                  </span>
                </template>

                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </b-form-group>
            <div v-else>
              {{ $t(`fee.${data.item.feeType}`) }}
            </div>
          </div>

          <!-- check theo số vé -->
          <HStack v-if="checkAgencyFeeByTicket(data.item.airline)">
            <div
              v-if="checkAgencyFeeByTicket(data.item.airline)?.value?.contentSource"
              class="fw-700"
            >
              {{ checkAgencyFeeByTicket(data.item.airline)?.value?.contentSource }}:
            </div>
            Theo số vé
          </HStack>
        </template>

        <template #cell(amount)="data">
          <div>
            <b-form-group
              v-if="feeDataToEdit.id === data.item.id"
              label-for="amount"
              class="custom-form-group"
            >
              <IAmInputMoney
                id="amount"
                :value-money.sync="feeDataToEdit.amount"
                class="flex-grow-1"
                :state="disabledUpdateFee ? false : null"
                :placeholder="$t('fee.placeholder.amount')"
              />
            </b-form-group>
            <div
              v-else
              class="text-right"
            >
              {{ formatCurrency(data.item.amount) }}
            </div>
          </div>
        </template>

        <template #cell(action)="data">
          <div class="text-center">
            <div
              v-if="feeDataToEdit.id === data.item.id"
              class="d-flex-center flex-nowrap gap-3"
            >
              <b-button
                class="text-center px-50"
                variant="flat-success"
                :disabled="isLoading"
                @click="updateFee(data.item)"
              >
                <feather-icon
                  icon="CheckIcon"
                  size="16"
                  style="cursor: pointer"
                />
              </b-button>
              <b-button
                class="text-center px-50"
                variant="flat-danger"
                :disabled="isLoading"
                @click="() => handleTriggerEditFee(data.item, false)"
              >
                <feather-icon
                  icon="XIcon"
                  size="16"
                  style="cursor: pointer"
                />
              </b-button>
            </div>
            <b-button
              v-else
              variant="flat-info"
              :disabled="isLoading"
              @click="() => handleTriggerEditFee(data.item, true)"
            >
              <feather-icon
                icon="EditIcon"
                size="16"
                style="cursor: pointer"
              />
            </b-button>
          </div>
        </template>
      </b-table>
    </BCard>
  </IAmOverlay>
</template>
<script>
import {
  BButton, BCard, BTable, BFormGroup,
} from 'bootstrap-vue'
import { ref, computed } from '@vue/composition-api'
import { createPopper } from '@popperjs/core'
import cloneDeep from 'lodash/cloneDeep'

import { feeTypeOptions } from '@/constants/selectOptions'
import { formatCurrency } from '@/@core/utils/filter'
import { apiAgencies } from '@/api'
import store from '@/store'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BFormGroup,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    IAmInputMoney: () => import('@/components/IAmInputMoney.vue'),
    vSelect: () => import('vue-select'),
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
  },
  props: {
    ticketType: {
      type: String,
      default: 'INLAND',
    },
    feeConfigsData: {
      type: Array,
      default: () => [],
    },
    agencyId: {
      type: [Number, null],
      default: null,
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    withPopper(dropdownList, component, { width }) {
      const dropdownMaxWidth = width || '200px'
      dropdownList.style.border = '1px solid #b8c2cc'
      dropdownList.style.maxWidth = dropdownMaxWidth
      dropdownList.style.zIndex = '10000'
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [],
      })
      return () => popper.destroy()
    },
  },
  setup(props, { emit }) {
    const { toastSuccess, toastError } = useToast()
    const tableColumnsRaw = ref([
      { label: 'airline', key: 'airline' },
      { label: 'feeType', key: 'feeType' },
      { label: 'amount', key: 'amount' },
      // { label: '', key: 'applyAll' },
      { label: 'action', key: 'action' },
    ])

    const typeUser = computed(() => store.getters['userStore/getMeDataType'])
    const tableColumns = computed(() => ['ADM', 'OPE'].includes(typeUser.value) ? tableColumnsRaw.value : tableColumnsRaw.value.filter(i => i.key !== 'action'))

    const isLoading = ref(false)

    const blankFeeData = {
      id: null,
      airline: null,
      amount: 0,
      feeType: null,
    }
    const feeDataToEdit = ref(cloneDeep(blankFeeData))

    const disabledUpdateFee = computed(() => !feeDataToEdit.value.amount && feeDataToEdit.value.amount !== 0)
    function handleTriggerEditFee(item, isEdit = true) {
      if (isEdit) {
        feeDataToEdit.value.id = item.id
        feeDataToEdit.value.airline = item.airline
        feeDataToEdit.value.amount = item.amount
        feeDataToEdit.value.feeType = item.feeType
      } else {
        feeDataToEdit.value = cloneDeep(blankFeeData)
      }
    }

    async function updateFee() {
      if (disabledUpdateFee.value) {
        toastError('Vui lòng nhập số tiền')
        return
      }
      isLoading.value = true
      try {
        const payload = {
          ...feeDataToEdit.value,
          id: undefined,
          ticketType: props.ticketType,
        }
        await apiAgencies.createFeeConfig(props.agencyId, payload)
        emit('refetch-data')
        feeDataToEdit.value = cloneDeep(blankFeeData)
        toastSuccess({ title: 'messagesList.success', content: 'fee.createFeeConfigSuccess' })
      } catch (e) {
        toastError({ title: 'messagesList.error', content: 'fee.createFeeConfigError' })
      } finally {
        isLoading.value = false
      }
    }
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const agencyFeeByTicketSources = computed(() => store.getters['globalConfig/getConfig']('agencyFeeByTicketSources'))
    function checkAgencyFeeByTicket(source) {
      if (isRoleF1.value) {
        const isAgencyFeeByTicket = agencyFeeByTicketSources.value.find(item => item.key === source)
        return isAgencyFeeByTicket
      }
      return null
    }

    return {
      tableColumns,
      formatCurrency,
      feeDataToEdit,
      feeTypeOptions,
      handleTriggerEditFee,
      isLoading,
      updateFee,
      disabledUpdateFee,
      agencyFeeByTicketSources,
      checkAgencyFeeByTicket,
    }
  },
}
</script>
<style lang="scss" scoped>
.d-one-line {
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.custom-form-group {
  margin-bottom: unset;
}
</style>
