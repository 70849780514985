var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.isLoading
    }
  }, [_c('BCard', [_c('b-table', {
    attrs: {
      "fields": _vm.tableColumns,
      "striped": "",
      "bordered": "",
      "hover": "",
      "responsive": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "items": _vm.feeConfigsData
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: column.label,
            staticClass: "text-dark text-nowrap",
            class: {
              'text-right': ['amount'].includes(data.label),
              'text-center': ['action'].includes(data.label)
            }
          }, [_vm._v(" " + _vm._s(data.label ? _vm.$t("fee.columns.".concat(data.label)) : '') + " ")])];
        }
      };
    }), {
      key: "cell(airline)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex-center gap-1 font-weight-bolder"
        }, [_c('div', {
          staticClass: "d-flex-center",
          staticStyle: {
            "width": "50px"
          }
        }, [_c('IAmLogoAirline', {
          attrs: {
            "airline": data.item.airline.toUpperCase(),
            "height": 30
          }
        })], 1), _c('span', {
          staticClass: "flex-1"
        }, [_vm._v(" " + _vm._s(_vm.$te("flight.airlines.".concat(data.item.airline.toUpperCase())) ? _vm.$t("flight.airlines.".concat(data.item.airline.toUpperCase())) : '') + " (" + _vm._s(_vm.$te("flight.sourceName.".concat(data.item.airline.toUpperCase())) ? _vm.$t("flight.sourceName.".concat(data.item.airline.toUpperCase())) : data.item.airline.toUpperCase()) + ") ")])])];
      }
    }, {
      key: "cell(feeType)",
      fn: function fn(data) {
        var _vm$checkAgencyFeeByT, _vm$checkAgencyFeeByT2, _vm$checkAgencyFeeByT3, _vm$checkAgencyFeeByT4, _vm$checkAgencyFeeByT5, _vm$checkAgencyFeeByT6;
        return [!_vm.checkAgencyFeeByTicket(data.item.airline) || (_vm$checkAgencyFeeByT = _vm.checkAgencyFeeByTicket(data.item.airline)) !== null && _vm$checkAgencyFeeByT !== void 0 && (_vm$checkAgencyFeeByT2 = _vm$checkAgencyFeeByT.value) !== null && _vm$checkAgencyFeeByT2 !== void 0 && _vm$checkAgencyFeeByT2.contentSource ? _c('div', [_vm.feeDataToEdit.id === data.item.id ? _c('b-form-group', {
          staticClass: "custom-form-group",
          staticStyle: {
            "min-width": "300px"
          },
          attrs: {
            "label-for": "feeTypeEdit"
          }
        }, [_c('v-select', {
          attrs: {
            "append-to-body": true,
            "calculate-position": _vm.withPopper,
            "options": _vm.ticketType === 'INLAND' ? _vm.feeTypeOptions.filter(function (item) {
              return item.value === 'FLIGHT_ITINERARY';
            }) : _vm.feeTypeOptions,
            "reduce": function reduce(val) {
              return val.value;
            },
            "clearable": false,
            "placeholder": _vm.$t('fee.placeholder.feeType')
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(_ref) {
              var value = _ref.value;
              return [_c('span', {
                staticClass: "d-one-line"
              }, [_vm._v(" " + _vm._s(_vm.$t("fee.".concat(value))) + " ")])];
            }
          }, {
            key: "selected-option",
            fn: function fn(_ref2) {
              var value = _ref2.value;
              return [_c('span', {
                staticClass: "d-one-line"
              }, [_vm._v(" " + _vm._s(_vm.$t("fee.".concat(value))) + " ")])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.feeDataToEdit.feeType,
            callback: function callback($$v) {
              _vm.$set(_vm.feeDataToEdit, "feeType", $$v);
            },
            expression: "feeDataToEdit.feeType"
          }
        })], 1) : _c('div', [_vm._v(" " + _vm._s(_vm.$t("fee.".concat(data.item.feeType))) + " ")])], 1) : _vm._e(), _vm.checkAgencyFeeByTicket(data.item.airline) ? _c('HStack', [(_vm$checkAgencyFeeByT3 = _vm.checkAgencyFeeByTicket(data.item.airline)) !== null && _vm$checkAgencyFeeByT3 !== void 0 && (_vm$checkAgencyFeeByT4 = _vm$checkAgencyFeeByT3.value) !== null && _vm$checkAgencyFeeByT4 !== void 0 && _vm$checkAgencyFeeByT4.contentSource ? _c('div', {
          staticClass: "fw-700"
        }, [_vm._v(" " + _vm._s((_vm$checkAgencyFeeByT5 = _vm.checkAgencyFeeByTicket(data.item.airline)) === null || _vm$checkAgencyFeeByT5 === void 0 ? void 0 : (_vm$checkAgencyFeeByT6 = _vm$checkAgencyFeeByT5.value) === null || _vm$checkAgencyFeeByT6 === void 0 ? void 0 : _vm$checkAgencyFeeByT6.contentSource) + ": ")]) : _vm._e(), _vm._v(" Theo số vé ")]) : _vm._e()];
      }
    }, {
      key: "cell(amount)",
      fn: function fn(data) {
        return [_c('div', [_vm.feeDataToEdit.id === data.item.id ? _c('b-form-group', {
          staticClass: "custom-form-group",
          attrs: {
            "label-for": "amount"
          }
        }, [_c('IAmInputMoney', {
          staticClass: "flex-grow-1",
          attrs: {
            "id": "amount",
            "value-money": _vm.feeDataToEdit.amount,
            "state": _vm.disabledUpdateFee ? false : null,
            "placeholder": _vm.$t('fee.placeholder.amount')
          },
          on: {
            "update:valueMoney": function updateValueMoney($event) {
              return _vm.$set(_vm.feeDataToEdit, "amount", $event);
            },
            "update:value-money": function updateValueMoney($event) {
              return _vm.$set(_vm.feeDataToEdit, "amount", $event);
            }
          }
        })], 1) : _c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(data.item.amount)) + " ")])], 1)];
      }
    }, {
      key: "cell(action)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-center"
        }, [_vm.feeDataToEdit.id === data.item.id ? _c('div', {
          staticClass: "d-flex-center flex-nowrap gap-3"
        }, [_c('b-button', {
          staticClass: "text-center px-50",
          attrs: {
            "variant": "flat-success",
            "disabled": _vm.isLoading
          },
          on: {
            "click": function click($event) {
              return _vm.updateFee(data.item);
            }
          }
        }, [_c('feather-icon', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "icon": "CheckIcon",
            "size": "16"
          }
        })], 1), _c('b-button', {
          staticClass: "text-center px-50",
          attrs: {
            "variant": "flat-danger",
            "disabled": _vm.isLoading
          },
          on: {
            "click": function click() {
              return _vm.handleTriggerEditFee(data.item, false);
            }
          }
        }, [_c('feather-icon', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "icon": "XIcon",
            "size": "16"
          }
        })], 1)], 1) : _c('b-button', {
          attrs: {
            "variant": "flat-info",
            "disabled": _vm.isLoading
          },
          on: {
            "click": function click() {
              return _vm.handleTriggerEditFee(data.item, true);
            }
          }
        }, [_c('feather-icon', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "icon": "EditIcon",
            "size": "16"
          }
        })], 1)], 1)];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }